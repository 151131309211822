import Rails from "@rails/ujs";
import axios from "axios";

document.addEventListener("turbolinks:load", () => {
  const logs = document.querySelectorAll("[data-log]");
  [].forEach.call(logs, (log) => {
    log.addEventListener("click", (e) => {
      e.preventDefault();

      const userId = log.getAttribute("data-user-id");
      const logCode = log.getAttribute("data-log");

      // CSRF token
      const csrfToken = document.querySelector("meta[name=csrf-token]").content;

      axios.post("/visitor_logs", {
        user_visitor_log: {
          user_id: userId,
          code: logCode
        }
      }, {
        headers: {
          "X-CSRF-Token": csrfToken
        }
      })
    });
  });
});
