document.addEventListener("turbolinks:load", () => {
  // Open menu
  const openBtn = document.querySelector("#open-menu-button");

  if (openBtn) {
    openBtn.addEventListener("click", () => {
      document.querySelector("#menu").classList.add("active");
    });
  }

  // Close menu
  const closeBtn = document.querySelector("#close-menu-button");

  if (closeBtn) {
    closeBtn.addEventListener("click", () => {
      document.querySelector("#menu").classList.remove("active");
    });
  }
});

document.addEventListener("turbolinks:before-cache", () => {
  const menu = document.querySelector("#menu");

  if (menu) {
    if (menu.classList.contains("active")) {
      menu.classList.remove("active");
    }
  }
});
