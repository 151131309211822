import "core-js/stable";
import "regenerator-runtime/runtime";

import baguetteBox from "baguettebox.js";
import Flickity from "flickity";
import "flickity-imagesloaded";
import "flickity/dist/flickity.min.css";
import LazyLoad from "vanilla-lazyload";

import Turbolinks from "turbolinks";
import Rails from "@rails/ujs";

import "src/sites/body_kizdar/scss/main.scss";

import "src/sites/body_kizdar/images";

import "src/sites/body_kizdar/js/important_rules";
import "src/sites/body_kizdar/js/back";
import "src/sites/body_kizdar/js/menu";
import "src/sites/body_kizdar/js/feedbacks";
import "src/sites/body_kizdar/js/set_on_click";
import "src/sites/body_kizdar/js/modal";
import "src/sites/body_kizdar/js/visitor_log";

Rails.start();
Turbolinks.start();

document.addEventListener("turbolinks:load", () => {

  window.addEventListener("scroll", () => {
    console.log("scroll");
    const navbarBurger = document.querySelector(".navbar__burger");
    if (window.scrollY > 26) {
      navbarBurger.classList.add("navbar__burger--scrolled");
    } else {
      navbarBurger.classList.remove("navbar__burger--scrolled");
    }
  });

  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy"
  });

  lazyLoadInstance.update();
  
  const popupClosers = document.querySelectorAll("[data-popup-close]");

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/sw_v3.js', { scope: "/" })
      .then(() => navigator.serviceWorker.ready.then((worker) => {
        worker.sync.register('syncdata');
      }))
      .catch((err) => console.log(err));
  }

  Array.from(popupClosers).forEach(popupClose => {
    popupClose.addEventListener("click", (e) => {
      e.preventDefault();
      document.querySelector(popupClose.dataset.popupClose).remove();
    })
  })

  if (document.querySelector(".photos-column")) {
    baguetteBox.run(".photos-column");

    var flickityEntity = null;

    actualResizeHandler();
  
    window.addEventListener("resize", resizeThrottler, false);
  
  
    var resizeTimeout;
    function resizeThrottler() {
      if ( !resizeTimeout ) {
        resizeTimeout = setTimeout(function() {
          resizeTimeout = null;
          actualResizeHandler();
  
         }, 66);
      }
    }
  
    function actualResizeHandler() {
      if (window.innerWidth < 769) {
        if (flickityEntity) { return; }
  
        const holder = document.querySelector(".photos-column");
  
        if (!holder) { return; }
  
        flickityEntity = new Flickity(holder, {
          cellAlign: "left",
          contain: true,
          imagesLoaded: true
        });
      } else {
        if (flickityEntity) {
          flickityEntity.destroy();
          flickityEntity = null;
        }
      }
    }
  }

  if (document.querySelector(".mobile-gallery")) {
    baguetteBox.run(".mobile-gallery");
  }
});

// Emit event for async loading of script
document.dispatchEvent(new Event("turbolinks:load"));
