import Cookies from "js-cookie";

document.addEventListener("turbolinks:load", () => {
  const btn = document.querySelector("#important-rules-modal-confirm");
  const mdl = document.querySelector("#important-rules-modal");
  if (btn) {
    btn.addEventListener("click", (e) => {
      e.preventDefault();
      Cookies.set("hide_rules", "1");
      mdl.classList.remove("is-active");
    })
  }
});
